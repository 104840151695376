<template>
    <div>
        <div id="auth-window">
            <img id="profile-picture" :src="titlePicture" alt="">
            <h3 id="user-name-preview">{{ $route.query.name ? $route.query.name : '' }}</h3>
            <h4 id="user-name-preview" style="margin-top: -38px; color: #656565; font-size: 27px">Password Reset</h4>

            <input @keyup="skipEvent" v-model="password" placeholder="Password" type="password">
            <input @keyup="skipEvent" v-model="passwordConfirmation" placeholder="Confirm Password" type="password">

            <h3 v-if="step == 2" style="text-align: center">Loading...</h3>
            <p class="error-message">{{ errorMessage }}</p>

            <a @click="nextStep" class="button highlighted" id="login-button">Next</a>

            <router-link to="/" id="register-action" style="float: none">Login</router-link>

        </div>
        <div id="bottom">
            <a href="https://interaapps.de/imprint">Imprint</a>
            <a href="https://interaapps.de/privacy">Privacy</a>
        </div>
    </div>
</template>

<script>
import helper from '../../helper'

export default {
    data: () => ({
        titlePicture: "https://cdn.interaapps.de/ia/icons/icon.svg",
        username: "",
        password: "",
        passwordConfirmation: "",
        preview: {
            profile_picture: "https://interaapps.de/assets/interaapps/icon/icon2.png"
        },
        errorMessage: "",
        redirectUrl: "/"
    }),
    methods: {
        nextStep() {
            if (this.password == this.passwordConfirmation) {
                helper.showSnackBar("Sending...")
                this.apiClient.post("/api/v2/auth/reset_password/" + this.$route.query.key, {
                    password: this.password
                })
                    .then(this.apiClient.errorCheck)
                    .then(() => {
                        this.$router.push("/login")
                    })
            } else if (this.password.length < 7) {
                this.errorMessage = "Password too short (Min. 6 characters)"
            } else {
                this.errorMessage = "The password confirmation doesn't equal the password."
            }
        },
        skipEvent(e) {
            if (e.keyCode == 13)
                this.nextStep()
        }
    }
}
</script>

<style lang="scss" scoped>
#bottom {
    position: fixed;
    bottom: 20px;
    left: 20px;

    a {
        color: #656565;
        text-decoration: none;
        margin-right: 10px;
    }
}
</style>